// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-aerosmart-en-js": () => import("./../../../src/templates/aerosmart-en.js" /* webpackChunkName: "component---src-templates-aerosmart-en-js" */),
  "component---src-templates-aerosmart-tr-js": () => import("./../../../src/templates/aerosmart-tr.js" /* webpackChunkName: "component---src-templates-aerosmart-tr-js" */),
  "component---src-templates-borehole-en-js": () => import("./../../../src/templates/borehole-en.js" /* webpackChunkName: "component---src-templates-borehole-en-js" */),
  "component---src-templates-borehole-logging-en-js": () => import("./../../../src/templates/borehole-logging-en.js" /* webpackChunkName: "component---src-templates-borehole-logging-en-js" */),
  "component---src-templates-borehole-logging-tr-js": () => import("./../../../src/templates/borehole-logging-tr.js" /* webpackChunkName: "component---src-templates-borehole-logging-tr-js" */),
  "component---src-templates-borehole-tr-js": () => import("./../../../src/templates/borehole-tr.js" /* webpackChunkName: "component---src-templates-borehole-tr-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-kuyu-olcum-ve-analiz-en-js": () => import("./../../../src/templates/kuyu-olcum-ve-analiz-en.js" /* webpackChunkName: "component---src-templates-kuyu-olcum-ve-analiz-en-js" */),
  "component---src-templates-kuyu-olcum-ve-analiz-js": () => import("./../../../src/templates/kuyu-olcum-ve-analiz.js" /* webpackChunkName: "component---src-templates-kuyu-olcum-ve-analiz-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-list-js": () => import("./../../../src/templates/posts-list.js" /* webpackChunkName: "component---src-templates-posts-list-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-aero-smart-mag-list-all-en-js": () => import("./../../../src/templates/products-aero-smart-mag-list-all-en.js" /* webpackChunkName: "component---src-templates-products-aero-smart-mag-list-all-en-js" */),
  "component---src-templates-products-aero-smart-mag-list-all-js": () => import("./../../../src/templates/products-aero-smart-mag-list-all.js" /* webpackChunkName: "component---src-templates-products-aero-smart-mag-list-all-js" */),
  "component---src-templates-products-borehole-logging-list-all-en-js": () => import("./../../../src/templates/products-borehole-logging-list-all-en.js" /* webpackChunkName: "component---src-templates-products-borehole-logging-list-all-en-js" */),
  "component---src-templates-products-borehole-logging-list-all-js": () => import("./../../../src/templates/products-borehole-logging-list-all.js" /* webpackChunkName: "component---src-templates-products-borehole-logging-list-all-js" */),
  "component---src-templates-products-borehole-seismic-list-all-en-js": () => import("./../../../src/templates/products-borehole-seismic-list-all-en.js" /* webpackChunkName: "component---src-templates-products-borehole-seismic-list-all-en-js" */),
  "component---src-templates-products-borehole-seismic-list-all-js": () => import("./../../../src/templates/products-borehole-seismic-list-all.js" /* webpackChunkName: "component---src-templates-products-borehole-seismic-list-all-js" */),
  "component---src-templates-products-rock-sampling-list-all-en-js": () => import("./../../../src/templates/products-rock-sampling-list-all-en.js" /* webpackChunkName: "component---src-templates-products-rock-sampling-list-all-en-js" */),
  "component---src-templates-products-rock-sampling-list-all-js": () => import("./../../../src/templates/products-rock-sampling-list-all.js" /* webpackChunkName: "component---src-templates-products-rock-sampling-list-all-js" */),
  "component---src-templates-rock-sampling-en-js": () => import("./../../../src/templates/rock-sampling-en.js" /* webpackChunkName: "component---src-templates-rock-sampling-en-js" */),
  "component---src-templates-rock-sampling-tr-js": () => import("./../../../src/templates/rock-sampling-tr.js" /* webpackChunkName: "component---src-templates-rock-sampling-tr-js" */),
  "component---src-templates-service-list-all-js": () => import("./../../../src/templates/service-list-all.js" /* webpackChunkName: "component---src-templates-service-list-all-js" */),
  "component---src-templates-service-list-cored-js": () => import("./../../../src/templates/service-list-cored.js" /* webpackChunkName: "component---src-templates-service-list-cored-js" */),
  "component---src-templates-service-list-rdt-js": () => import("./../../../src/templates/service-list-rdt.js" /* webpackChunkName: "component---src-templates-service-list-rdt-js" */)
}

